<template>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh"
                      class="courseEvaluate">
        <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad">
            <BarList :list="list" @callMore="(item)=>{callMore(item, '/liveDetails')}"/>
        </van-list>
    </van-pull-refresh>
</template>

<script>
import BarList from './../../home/components/BarList';
import DropDownRefresh from '../../../mixin/DropDownRefresh';
import api from '../../../api/common';
export default {
    name: "courseEvaluate",
    components: {
        BarList
    },
    mixins: [DropDownRefresh],
    data () {
        return {
            listQuery: {
                current: 1,
                size: 5
            }
        };
    },
    methods: {
        callMore (item, url) {
            this.$utils.callMores(item, url);
        },
        // 查询专题下的直播
        getList () {
            api.queryHomeLiveList(this.$route.query.id, this.listQuery).then(res => {
                if (res) {
                    this.onEnd(res.total, res.records);
                }
            });
        }
    }
}
</script>

<style scoped lang="less">
</style>