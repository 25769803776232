<template>
    <baseContainer>
        <template v-slot:heads>
            <HeadNav/>
            <div class="columnDetails_headers">
                <div class="headers_item">
                    <b>专题名称：</b>{{details.name}}
                </div>
                <div class="headers_item">
                    <b>所属机构：</b>{{details.corpName}}
                </div>
                <div class="headers_item">
                    <b>包含直播：</b>{{details.liveCount}}
                    <b class="twos">包含课程：</b>{{details.courseCount}}
                </div>
            </div>
            <van-tabs v-model="activeName" class="custom_tabs">
                <van-tab title="课程" name="a"/>
                <van-tab title="直播" name="b"/>
            </van-tabs>
        </template>
        <div class="columnDetails">
            <van-tabs v-model="activeName" class="custom_tabs spaeil">
                <van-tab title="课程" name="a">
                    <van-pull-refresh class="courseArrange" v-model="refreshing" @refresh="onRefresh">
                        <div>
                            <van-list
                                    v-model="loading"
                                    :finished="finished"
                                    finished-text="没有更多了"
                                    @load="onLoad">
                                <BarList :type="pageType.course"
                                         :list="list"
                                         :propType="propType"
                                         @callMore="(item)=>{callMore(item, '/courseDetails')}"/>
                            </van-list>
                        </div>
                    </van-pull-refresh>
                </van-tab>
                <van-tab title="直播" name="b">
                    <ColumnLive class="courseArrange"/>
                </van-tab>
            </van-tabs>
        </div>
    </baseContainer>
</template>

<script>
import HeadNav from '../../components/headNav';
import constant from '../../utils/constant';
import BarList from './../home/components/BarList';
import ColumnLive from './column/columnLive';
import CacheTabs from './../../mixin/CacheTabs';
import DropDownRefresh from './../../mixin/DropDownRefresh';
import api from './../../api/common';

export default {
    name: "columnDetails",
    components: {
        HeadNav,
        BarList,
        ColumnLive
    },
    mixins: [CacheTabs, DropDownRefresh],
    data() {
        return {
            pageType: constant.pageType,
            details: {},
            listQuery: {
                current: 1,
                size: 5
            },
            propType: {
                img: 'courseCover',
                name: 'name',
                date: 'startTime',
                user: 'teacherName',
                status: 'status'
            }
        }
    },
    methods: {
        callMore(item, url) {
            this.$utils.callMores(item, url);
        },
        getSubjectIndex() {
            api.getSubjectIndex(this.$route.query.id).then(res => {
                this.details = res;
            });
        },
        // 查询专题下的课程
        getList() {
            api.queryHomeCourseList(this.$route.query.id, this.listQuery).then(res => {
                if (res) {
                    this.onEnd(res.total, res.records);
                }
            });
        }
    },
    mounted() {
        this.getSubjectIndex();
    }
}
</script>

<style scoped lang="less">
    .columnDetails_headers {
        .headers_item {
            padding: 36px 40px;
            border-bottom: 1px solid #F5F5F5;
            font-size: 28px;
            color: #353535;
            .twos {
                margin-left: 80px;
            }
        }
    }

    .courseArrange {
        min-height: 50vh;
        padding: 10px 40px 40px;
    }
</style>