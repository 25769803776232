import { render, staticRenderFns } from "./columnLive.vue?vue&type=template&id=78b7c030&scoped=true&"
import script from "./columnLive.vue?vue&type=script&lang=js&"
export * from "./columnLive.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78b7c030",
  null
  
)

export default component.exports